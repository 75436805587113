import { Hook } from './utils';

export abstract class BaseHookHandler<
  T extends Realtime.HookSignature<T> = Realtime.HookSignature<unknown>,
> {
  protected hooks: { [index in keyof T]: Realtime.Hook<T[index]> };

  constructor() {
    this.hooks = {} as { [index in keyof T]: Realtime.Hook<T[index]> };
  }

  /**
   *  Trigger event and hook, it returns hook result
   */
  protected async trigger<K extends keyof T>(event: K, ...args: Parameters<T[K]>) {
    // trigger event
    if (this.hooks[event]) {
      const result = await this.hooks[event].trigger(...args);
      return result;
    }
  }

  register<K extends keyof T>(event: K, callback: T[K]) {
    if (this.hooks[event] === undefined) {
      this.hooks[event] = new Hook<T[K]>();
    }
    this.hooks[event].register(callback);
    return this;
  }

  destroy() {
    const hookKeys = Object.typedKeys(this.hooks);
    for (let i = 0; i < hookKeys.length; i++) {
      this.hooks[hookKeys[i]].clear();
      delete this.hooks[hookKeys[i] as keyof T];
    }
  }
}
